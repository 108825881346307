import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Courses from "./pages/Courses";
import Contact from "./pages/Contact";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Courses1 from "./pages/Courses1";
import Courses2 from "./pages/Courses2";
import Career from "./pages/Career";
import Refund from "./pages/Refund";
import Tc from "./pages/Tc";
import Privacy from "./pages/Privacy";
import Notification from "./components/Notification";
import Payment from "./pages/Payment";
import NotFound from "./pages/PageNotFound";
import Status from "./pages/Status";

const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen ">
        <Notification />
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services" element={<Services />} />
            <Route path="/mernstack" element={<Courses />} />
            <Route path="/reactnative" element={<Courses1 />} />
            <Route path="/reactjs" element={<Courses2 />} />
            <Route path="/careers" element={<Career />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/termsandconditions" element={<Tc />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/status/:id" element={<Status />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
