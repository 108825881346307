import React,{useState} from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { TabMenu } from 'primereact/tabmenu';
import { Carousel } from 'primereact/carousel';
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme for PrimeReact
import 'primereact/resources/primereact.min.css';          // Core CSS for PrimeReact
import 'primeicons/primeicons.css';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import Modal from '../components/Modal';
import EnrollForm from '../components/EnrollForm';

const Courses1 = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);
    
    const handleEnrollClick = () => {
      setIsModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };
    const tools = [
        { title: 'GitHub', icon: '/assets/img/github.svg' },
        { title: 'AWS', icon: '/assets/img/aws.svg' },
        { title: 'Netlify', icon: '/assets/img/netlify.svg' },
        { title: 'Git', icon: '/assets/img/git.svg' },
        { title: 'CircleCI', icon: '/assets/img/circleci.svg' },

    ];

    const skills = [

        { icon: '/assets/img/n2.png', title: 'JavaScript' },
        { icon: '/assets/img/native.svg', title: 'React' },
        { icon: '/assets/img/n1.png', title: 'Redux' },
        { icon: '/assets/img/react.svg', title: 'React Navtive cli' },
        { title: 'Expo', icon: '/assets/img/expo.svg' },



    ];

    const faqCategories = [
        'Eligibility & Application',
        'Program prerequisites',
        'Payment',
        'Start date & duration',
        'Job Placement',
        'Certifications',
        'Course',
        'Mentorship',
        'MERN Stack'
    ];
    const faqs = [
        {
            question: "What topics are covered in a React Native course?",
            answer: "Our React Native course covers fundamental concepts such as component development, state management, navigation, integrating APIs, and handling native modules. It also includes advanced topics like performance optimization, testing, and deployment."
        },

        {
            question: "Are React Native courses suitable for beginners?",
            answer: "Yes, our React Native courses are designed for learners of all levels. They include foundational lessons that cover the basics of React Native, making them suitable for beginners. More advanced topics are introduced as you progress."
        },
        {
            question: "What companies hire graduates from Streakify Technologies?",
            answer: "Graduates from Streakify Technologies are highly sought after by both multinational corporations (MNCs) and innovative startups. Our alumni have secured positions in companies offering competitive salaries, often exceeding 7 LPA."
        },

        {
            question: "What is the duration of the React Native course, and how is it structured?",
            answer: "The duration of our React Native course varies depending on the plan you choose. It typically includes a series of modules with a combination of video lectures, practical assignments, and quizzes. The course can be completed at your own pace, with flexible scheduling options."
        },
        {
            question: "Will I receive a certificate upon completing the React Native course?",
            answer: "Yes, upon successfully completing the React Native course, you will receive a certificate of completion. This certificate can be added to your resume or LinkedIn profile to showcase your skills to potential employers."
        },
        {
            question: "Are there any prerequisites for enrolling in the React Native course?",
            answer: "While there are no strict prerequisites, having a basic understanding of JavaScript and React is beneficial. Our course materials are designed to help you build on these foundational skills if you're new to them."
        },

        {
            question: "What kind of support is available during the React Native course?",
            answer: "We offer comprehensive support through various channels, including access to instructors via email, live chat, and discussion forums. Additionally, you can participate in group study sessions and receive feedback on your assignments to ensure you understand the material effectively."
        },


    ];
    const options = [
        {
            image: '/path-to-mern-icon.png',
            title: 'MERN Stack Developer',
            description: 'Master all layers of web development using MongoDB, Express, React, and Node.js.'
        },
        {
            image: '/path-to-react-icon.png',
            title: 'React Frontend Developer',
            description: 'Specialize in creating dynamic and responsive user interfaces with React.'
        },
        {
            image: '/path-to-node-icon.png',
            title: 'Node.js Backend Developer',
            description: 'Build scalable server-side applications and APIs using Node.js and Express.'
        },
        {
            image: '/path-to-mongodb-icon.png',
            title: 'MongoDB Database Engineer',
            description: 'Design and manage NoSQL databases to store and retrieve data efficiently.'
        }
    ];

    const menuItems = [

        { label: 'Guaranteed Placement' },
    ];
    const categories = ['Big brands', 'Work from home', 'Part-time', 'MBA', 'Engineering', 'Media', 'Design', 'Data Science'];


    const CareerOption = ({ image, title, description }) => (
        <div className="bg-white p-4 rounded-lg shadow-md">
            <img src={image} alt={title} className="w-16 h-16 mb-4" />
            <h3 className="font-bold text-lg mb-2">{title}</h3>
            <p className="text-sm text-gray-600">{description}</p>
        </div>
    );
    return (
        <div className="relative overflow-hidden pb-16">
            <section className="relative overflow-hidden pb-16 bg-white dark:bg-slate-900 dark:text-white">

                {/* Section Wrapper for Main Content */}
                <div className="relative z-10 flex flex-col items-start text-left text-slate-700 w-full h-full py-16 px-8 md:px-16 lg:px-24">
                    {/* Main Content */}
                    <div className="relative z-10 flex flex-col lg:flex-row items-start w-full">
                        <div className="lg:w-1/2 lg:pr-8">
                            <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4 dark:text-gray-300 text-center lg:text-left">
                                REACT NATIVE DEVELOPMENT COURSE
                            </h1>

                            <p className="text-lg md:text-xl font-semibold mb-4 md:mb-8 text-center lg:text-left">
                                Join our React Native Development course with guaranteed placement and comprehensive internship opportunities.
                            </p>
                            <div className="flex flex-wrap justify-center lg:justify-start space-x-6 mb-6">
                                <div className="flex space-x-4 mb-4">
                                    <img src="/assets/img/native.svg" alt="React Native" className="h-10 md:h-12" />
                                    <img src="/assets/img/expo.svg" alt="expo" className="h-10 md:h-12" />
                                </div>
                                <div className="flex space-x-4">
                                    <img src="/assets/img/node-js.svg" alt="Node.js" className="h-10 md:h-12" />
                                    <img src="/assets/img/n2.png" alt="JavaScript" className="h-10 md:h-12" />
                                </div>
                            </div>
                            <div className="mb-4 md:mb-8 flex flex-col md:flex-row md:space-x-4 space-y-2 md:space-y-0">
                                {/* <button
                                    type="button"
                                    className="w-full md:w-auto text-pink-600 font-bold border border-pink-700 hover:bg-pink-500 hover:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center flex items-center justify-center"
                                >
                                    Download Curriculum
                                    <i className="pi pi-chevron-down ml-2"></i>
                                </button> */}
                                <button
                                 onClick={openModal}
                                    type="button"
                                    className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center mt-2 md:mt-0 md:ml-2"
                                >
                                    Enroll Now
                                    <i className="pi pi-chevron-down ml-2"></i>
                                </button>
                            </div>
                        </div>
                        {/* Image */}
                        <div className="relative lg:w-1/2 flex justify-center lg:justify-end lg:ml-8 lg:mt-8 mt-4 hidden lg:block">
                            <img
                                src="/assets/img/hero1.png"
                                alt="React Native Development"
                                className="w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl transform -translate-y-16 -translate-x-2"
                            />
                        </div>
                    </div>

                    <div className="absolute top-2 space-x-4 flex flex-wrap lg:space-x-6">

                        <button type="button" className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-2 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2">
                            AI Powered
                        </button>
                    </div>

                    <div className="flex flex-wrap justify-center gap-4 mt-6 bg-gray-100 dark:bg-gray-900 p-4 rounded-lg border-t-pink-400 border-r-teal-600 border-b-teal-600 border-l-pink-400 max-h-[30%] dark:text-gray-300 py-12">
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-calendar" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Online/Offline</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">Flexible learning options</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-clock" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Total Curriculum Duration</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">6 Months</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-calendar-times" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Course Duration</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">3 Months</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-calendar-times" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Internship Duration</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">3 Months</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-check" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Certificate</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">Completion certificate provided</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-briefcase" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">100% Placement Assistance</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">We help you land your dream job</div>
                            </div>
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-1/5 text-left mb-4 flex items-center">
                            <div className="w-10 h-10 flex items-center justify-center rounded-full bg-gradient-to-r from-pink-600 to-pink-400 text-white mr-4">
                                <i className="pi pi-mobile" style={{ fontSize: '1.25rem' }}></i> {/* Unique icon */}
                            </div>
                            <div>
                                <div className="font-bold text-slate-700 dark:text-gray-300">Dedicated Support</div>
                                <div className="text-sm text-gray-600 dark:text-gray-400">24/7 support for students</div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2 className="text-2xl font-bold mb-4">Enroll in the Course</h2>
        <EnrollForm onClose={handleCloseModal}/>
      </Modal>

            <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-900 py-12">
                <div className="max-w-6xl mx-auto px-4">
                    {/* React Native Overview */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
                            React Native Developer Course Overview
                            <span className="block h-1 w-24 bg-pink-500 mt-2"></span>
                        </h2>
                        <div className="flex flex-wrap items-center mb-8">
                            <div className="w-full md:w-1/2 mb-8 md:mb-0">
                                <img src="/assets/img/rn.png" alt="React Native" className="w-full h-auto object-cover rounded-none lg:w-1/2 lg:h-auto lg:rounded-lg" />
                            </div>


                            <div className="w-full md:w-1/2">
                                <p className="text-gray-600 dark:text-gray-400 mb-4">
                                    Enroll in Unique Technologies' premier React Native Developer Course, which covers a complete range of topics including JavaScript, JSX, React Navigation, Redux,expo, Native Modules,hooks,context,react navigation and more.
                                </p>
                                <p className="text-gray-600 dark:text-gray-400 mb-4">
                                    React Native allows you to build mobile applications using JavaScript and React. In our course, you will learn how to create both iOS and Android apps with a single codebase, making development more efficient and streamlined.
                                </p>
                                <p className="text-gray-600 dark:text-gray-400 mb-4">
                                    Our React Native Developer Course provides you with the skills to develop mobile applications using React Native, providing a native look and feel with excellent performance.
                                </p>
                            </div>
                        </div>
                    </section>

                    {/* Scope of React Native Course */}
                    <section className="mb-12">
                        <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
                            Scope for React Native Developer
                            <span className="block h-1 w-24 bg-pink-500 mt-2"></span>
                        </h2>
                        <p className="text-gray-600 dark:text-gray-400 mb-4">
                            Become a certified React Native Developer by enrolling in our course and start developing mobile applications using a single codebase for both iOS and Android. React Native developers are in great demand, and companies are offering higher packages for skilled developers.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400 mb-4">
                            React Native provides a great user experience, making React Native Developers highly sought after in the mobile app development industry.
                        </p>
                    </section>

                    {/* Insights on Training Program */}
                    <section>
                        <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
                            React Native Developer Course: Insights on Training Program
                            <span className="block h-1 w-24 bg-pink-500 mt-2"></span>
                        </h2>
                        <p className="text-gray-600 dark:text-gray-400 mb-4">
                            Our React Native Developer Course prepares you to update your skills and increase your knowledge about mobile app development. We provide brief learning of different modules to the students for a better learning experience, making our training program one of the best.
                        </p>
                        <p className="text-gray-600 dark:text-gray-400 mb-4">
                            In our course, you will learn:
                        </p>
                        <ul className="list-disc list-inside text-gray-600 dark:text-gray-400">
                            <li>Constructing complex user interfaces using React Native</li>
                            <li>Creating mobile applications using JavaScript and JSX</li>
                            <li>Utilizing React Navigation, Redux, expo, and Native Modules</li>
                            <li>Designing and building mobile applications efficiently</li>
                        </ul>
                    </section>

                    <div className="flex flex-wrap items-center justify-between py-12">
                        <div className="w-full md:w-1/2 px-4 mb-8">
                            <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-200">
                                Why Learn React Native?
                                <span className="block h-1 w-24 bg-pink-500 mt-2"></span>
                            </h2>
                            <ul className="list-disc list-inside text-gray-600 dark:text-gray-400 space-y-4">
                                <li>Comprehensive Framework: Build applications for both iOS and Android using a single codebase.</li>
                                <li>High Demand: React Native developers are highly sought after in the job market.</li>
                                <li>Efficiency: Gain skills to develop mobile applications quickly and efficiently.</li>
                                <li>JavaScript Everywhere: Use a single language for the entire development process.</li>
                                <li>Scalability: Create scalable and high-performance applications.</li>
                                <li>Strong Community Support: Access extensive resources and community help.</li>
                            </ul>
                        </div>
                        <div className="w-full md:w-1/2 px-4 mb-8">

                            <img src="/assets/img/greact.svg" alt="Native Modules" className="h-34 md:h-25" />
                        </div>


                    </div>
                </div>
            </div>






            <section className="py-12 bg-gray-50 dark:bg-gray-900">
                <div className="max-w-5xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-200 text-center">
                        How Your Training Journey Unfolds
                        <span className="block h-1 w-24 bg-pink-500 mt-2 mx-auto"></span>
                    </h2>
                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                                <img src="/assets/img/learn.svg" alt="Master Fundamentals" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                <div>
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Master Fundamentals</h3>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        Dive into comprehensive training videos to master key concepts of React Native. Our curriculum is designed to build a strong foundation.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                                <img src="/assets/img/test.svg" alt="Evaluate Your Skills" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                <div>
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Evaluate Your Skills</h3>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        Test your knowledge with quizzes and module tests at regular intervals. These assessments help reinforce learning and monitor your progress.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                                <img src="/assets/img/hands.svg" alt="Hands-on Projects" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                <div>
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Hands-on Projects</h3>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        Engage in practical assignments and projects using our in-browser IDE. This hands-on approach solidifies your understanding and application of React Native concepts.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap -mx-4">
                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                                <img src="/assets/img/doubt.svg" alt="Expert Support" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                <div>
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Expert Support</h3>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        Resolve your doubts swiftly with expert assistance through our Q&A forum, ensuring personalized support and timely responses.
                                    </p>
                                </div>
                            </div>
                        </div>



                        <div className="w-full md:w-1/3 px-4 mb-8">
                            <div className="flex flex-col items-start bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 hover:shadow-xl dark:hover:bg-blue-900">
                                <img src="/assets/img/certification.svg" alt="Earn Certification" className="w-full h-40 object-cover mb-4 rounded-t-lg" />
                                <div>
                                    <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Earn Certification</h3>
                                    <p className="text-gray-600 dark:text-gray-400">
                                        Upon successfully completing the final project, receive a certificate recognizing your proficiency in React Native. Enhance your resume and LinkedIn profile with this credential.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="py-12 bg-white dark:bg-gray-800">
                <div className="max-w-7xl mx-auto px-4">
                    <h2 className="text-3xl font-bold mb-8 text-gray-800 dark:text-gray-200 text-center">
                        Our Competitive Edge
                        <span className="block h-1 w-24 bg-pink-500 mt-2 mx-auto"></span>
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-pencil text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Free Registration for All</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Register for free. Enroll in courses under our 100% Refund offer and earn your enrollment fee back as a reward.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-book text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Comprehensive Course Structure</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Learn from our structured pre-recorded courses created by experts to meet industry needs.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-users text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">24/7 Mentor Support</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Personal mentors to guide and help you throughout your journey via chat, calls, and screen sharing.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-file text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Hands-On Projects & Certificates</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Build major projects that make your resume stand out, along with course completion certifications.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-briefcase text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">Internship Opportunities</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Get remote internship opportunities after course completion along with dedicated assignments.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-6 bg-gray-100 dark:bg-gray-700 rounded-lg shadow-lg">
                            <i className="pi pi-percentage text-pink-500 text-4xl mb-4"></i>
                            <h3 className="text-xl font-semibold mb-2 text-gray-800 dark:text-gray-200">100% Placement Assistance</h3>
                            <p className="text-gray-600 dark:text-gray-400 text-center">
                                Enjoy lifetime access to each course you enroll in, with the benefit of mentoring whenever you want.
                            </p>
                        </div>
                    </div>
                </div>
            </section>


            <section className="py-12 bg-gray-100 dark:bg-gray-900">
                <div className="max-w-4xl mx-auto px-4"> {/* Reduced max width */}
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                            Skills You Will Learn
                            <span className="block h-1 w-24 bg-pink-500 mt-2 mx-auto"></span>
                        </h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {skills.map((skill, index) => (
                            <div
                                key={index}
                                className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900"
                            >
                                <div className="flex-shrink-0 w-10 h-10 bg-sky-100 dark:bg-sky-700 rounded-full flex items-center justify-center mb-4 mx-auto"> {/* Reduced size */}
                                    <img src={skill.icon} alt={skill.title} className="w-8 h-8 text-sky-500" /> {/* Reduced size */}
                                </div>
                                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">{skill.title}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <section className="py-12 bg-gray-100 dark:bg-gray-900">
                <div className="max-w-4xl mx-auto px-4">
                    <div className="text-center mb-12">
                        <h2 className="text-3xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                            Tools You Will Learn And Use
                            <span className="block h-1 w-24 bg-pink-500 mt-2 mx-auto"></span>
                        </h2>
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                        {tools.map((tool, index) => (
                            <div
                                key={index}
                                className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg text-center transition-transform transform hover:scale-105 hover:shadow-xl hover:bg-blue-50 dark:hover:bg-blue-900"
                            >
                                <div className="flex-shrink-0 w-10 h-10 bg-sky-100 dark:bg-sky-700 rounded-full flex items-center justify-center mb-4 mx-auto">
                                    <img src={tool.icon} alt={tool.title} className="w-8 h-8 text-sky-500" />
                                </div>
                                <h3 className="text-xl font-semibold text-gray-800 dark:text-gray-200">{tool.title}</h3>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            <div className="w-full min-h-screen bg-gray-100 dark:bg-gray-800 py-16 px-4 flex items-center justify-center">
                <div className="w-full max-w-6xl">
                    <div className="flex flex-col md:flex-row bg-white dark:bg-gray-900 p-10 ">
                        <div className="md:w-1/2 pr-8">
                            <h2 className="text-3xl font-bold mb-4 text-gray-800 dark:text-gray-100">
                                Obtain Your Certification
                                <span className="block h-1 w-24 bg-pink-500 mt-2 dark:bg-sky-400"></span>
                            </h2>
                            <p className="text-gray-600 mb-8 dark:text-gray-300">
                                Earn your certification that validates your skills and readiness for the industry once you complete your projects and pass the final assessment.
                            </p>

                            <div className="space-y-6">
                                {[
                                    {
                                        icon: "/assets/img/certi.png",
                                        title: "Industry-Ready Certification",
                                        description: "Our certification proves your job readiness, distinguishing you from other candidates."
                                    },
                                    {
                                        icon: "/assets/img/share.svg",
                                        title: "Shareable and Credible",
                                        description: "Easily add it to your LinkedIn, share on social media, or include it in your resume to enhance your profile."
                                    },
                                    {
                                        icon: "/assets/img/search.svg",
                                        title: "Stand Out to Employers",
                                        description: "This certification can attract higher salary offers and opportunities by showcasing your industry readiness."
                                    }
                                ].map((item, index) => (
                                    <div key={index} className="flex items-start mb-6 md:mb-8">
                                        <div className="w-16 h-16 md:w-16 md:h-16 flex items-center justify-center mr-4">
                                            <img src={item.icon} alt={item.title} className="w-14 h-14 md:w-12 md:h-12" />
                                        </div>
                                        <div>
                                            <h3 className="font-semibold text-lg text-gray-800 dark:text-gray-100">{item.title}</h3>
                                            <p className="text-gray-600 dark:text-gray-300">{item.description}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="mt-8">
                                <button type="button" className="text-white bg-gradient-to-r from-pink-500 via-pink-600 to-pink-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-500 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-sky-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                                    Book Your Session
                                </button>
                            </div>
                        </div>

                        <div className="md:w-1/2 mt-8 md:mt-0 flex justify-center items-center">
                            <div className="w-64 h-64 md:w-80 md:h-80  flex items-center justify-center ">
                                <img src="/assets/img/cc1.png" alt="Certificate" className="w-6/27 h-3/5 " />
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            <div className="max-w-4xl mx-auto p-8   rounded-lg">
                <h2 className="text-2xl font-bold mb-6   dark:text-white text-gray-800">Frequently Asked Questions</h2>

                <TabView>
                    <TabPanel>
                        <Accordion multiple className="border-t-1 border-pink-500">
                            {faqs.map((item, index) => (
                                <AccordionTab
                                    key={index}
                                    header={item.question}
                                    className="border-b-2 border-pink-300"
                                >
                                    <p className="text-gray-600">{item.answer}</p>
                                </AccordionTab>
                            ))}
                        </Accordion>
                    </TabPanel>
                </TabView>
            </div>

            <section className="relative min-h-screen bg-gray-100 dark:bg-slate-900 dark:text-white flex items-center overflow-hidden pb-16">
                <div className="container mx-auto flex flex-wrap">
                    {/* Left Side: Content */}
                    <div className="w-full lg:w-1/2 px-4 lg:px-8 mb-8 lg:mb-0 flex items-center">
                        <div className="text-center lg:text-left">
                            <h2 className="text-4xl font-bold mb-6 text-gray-800 dark:text-gray-200">
                                Need More Information? We're Here to Help You Out!
                            </h2>
                            <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                                We provide personalized assistance to address all your queries and guide you through the process. Feel free to reach out, and our team will be happy to assist you with your concerns and provide the information you need.
                                <br /><br />
                                Our team will be contacting you within 24 hours to answer any questions you may have and provide additional details!
                            </p>


                        </div>
                    </div>

                    {/* Right Side: Form */}
                    <div className="w-full lg:w-1/2 px-4 lg:px-8">
                        <div className="bg-white dark:bg-slate-800 p-8 shadow-lg rounded-lg">
                            <form>
                                <div className="mb-4">
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Your Name *
                                    </label>
                                    <InputText
                                        id="name"
                                        placeholder="Your Name"
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="whatsapp" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Your Whatsapp Number *
                                    </label>
                                    <InputText
                                        id="whatsapp"
                                        placeholder="Your Whatsapp Number"
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                                        required
                                    />
                                </div>
                                <div className="mb-4">
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                                        Email *
                                    </label>
                                    <InputText
                                        type="email"
                                        id="email"
                                        placeholder="Email Address"
                                        className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 dark:border-gray-600 dark:bg-gray-800 dark:text-white sm:text-sm"
                                        required
                                    />
                                </div>
                                <div className="flex justify-center">
                                    <button
                                        type="submit"
                                        className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 shadow-lg shadow-pink-500/50 dark:shadow-lg dark:shadow-pink-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                                    >
                                        Contact Us
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );
};

export default Courses1;
