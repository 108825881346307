import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import axiosInstance from '../Api/axios';

export default function Payment() {
  const location = useLocation();
  const navigate = useNavigate();
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();

  useEffect(() => {
    if (!location.state || !location.state.course) {
      // Redirect to home page if no course data
      navigate('/', { replace: true });
    } else {
      setCourse(location.state.course);
    }
  }, [location.state, navigate]);

  const onSubmit = async (data) => {
    setLoading(true);
    try {
      const response = await axiosInstance.post('/payments', {
        name: data.name,
        phone: data.phone,
        amount: course.discountedPrice,
      });

      const paymentUrl = response.data; // Assuming the backend returns the redirect URL

      if (paymentUrl) {
        window.location.href = paymentUrl; // Redirect to payment gateway
      } else {
        console.error('No payment URL received');
      }
    } catch (error) {
      console.error('Payment error:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6 flex flex-col items-center justify-center min-h-screen">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full text-center">
          <h2 className="text-2xl font-bold mb-6">Processing Payment</h2>
          <div className="flex items-center justify-center">
            <svg className="w-16 h-16 animate-spin text-cyan-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0 1 16 0H4z"></path>
            </svg>
            <p className="mt-4 text-lg text-gray-700 dark:text-gray-300">Please wait while we process your payment.</p>
          </div>
        </div>
      </div>
    );
  }

  if (!course) {
    return null; // Redirecting or showing a loader
  }

  return (
    <div className="container mx-auto p-6 flex flex-col items-center justify-center min-h-screen">
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg max-w-lg w-full">
        <h2 className="text-2xl font-bold mb-6 text-center py-4">Payment for {course.title}</h2>
        <img
          src={course.image}
          alt={course.title}
          className="w-full h-48 object-cover rounded-t-lg mb-4"
        />
        <p className="mb-4 text-center">{course.description}</p>
        <div className="mb-4 text-center">
          <span className="text-lg text-gray-700 dark:text-gray-300 line-through">
            ₹{course.originalPrice}
          </span>
          <span className="text-lg text-red-500 dark:text-red-400 font-bold ml-2">
            ₹{course.discountedPrice}
          </span>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Full Name</label>
            <input
              type="text"
              {...register('name', { required: 'Full Name is required' })}
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-gray-300"
            />
            {errors.name && <p className="text-red-500 text-sm">{errors.name.message}</p>}
          </div>
          <div>
            <label className="block text-gray-700 dark:text-gray-300">Phone Number</label>
            <input
              type="tel"
              {...register('phone', { required: 'Phone Number is required' })}
              className="w-full px-4 py-2 mt-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:bg-gray-900 dark:text-gray-300"
            />
            {errors.phone && <p className="text-red-500 text-sm">{errors.phone.message}</p>}
          </div>
          <button
            type="submit"
            className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
          >
            Complete Purchase
          </button>
        </form>
      </div>
    </div>
  );
}
