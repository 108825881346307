import React from 'react';
import { Divider } from 'primereact/divider';
import { BreadCrumb } from 'primereact/breadcrumb';
import 'primeicons/primeicons.css';
import ServiceCard from '../components/ServiceCard';
import SectionCard from '../components/SectionCard';
import CourseCard from '../components/Course';
import { Helmet } from "react-helmet";

const About = () => {
    const sections = [
        { title: 'Provide Cutting-edge Digital Solutions', description: 'We offer innovative digital solutions to meet your business needs.', icon: 'pi pi-cog' },
        { title: 'Empower Businesses', description: 'We empower businesses to thrive in the digital era.', icon: 'pi pi-briefcase' },
        { title: 'Foster Long-term Partnerships', description: 'We believe in building long-term partnerships with our clients.', icon: 'pi pi-users' },
    ];

    const services = [
        { title: 'Web Development', description: 'Create responsive and engaging websites tailored to your business needs.', image: '/assets/img/programming-background-with-person-working-with-codes-computer.jpg' },
        { title: 'Mobile App Development', description: 'Build innovative mobile applications for iOS and Android platforms.', image: '/assets/img/representation-user-experience-interface-design.jpg' },
        { title: 'Graphic Designing', description: 'Create visually appealing designs for your brand and marketing materials.', image: '/assets/img/pexels-junior-teixeira-1064069-2047905.jpg' },
        { title: 'Software Solutions', description: 'Develop customized software solutions tailored to your business requirements.', image: '/assets/img/standard-quality-control-concept-m.jpg' },
        { title: 'Digital Marketing', description: 'Grow your business with strategic digital marketing solutions.', image: '/assets/img/corporate-management-strategy-solution-branding-concept.jpg' },
    ];

    const cour = [
        { title: 'Advantages of Enrolling in Our Courses', description: 'Discover why students prefer our courses for the best web development training.', icon: 'pi pi-graduation-cap' },
        { title: 'One-on-One Training Program', description: 'Personalized attention with limited students in each batch for better learning.', icon: 'pi pi-user' },
        { title: 'Focus on Every Individual', description: 'Intakes fewer students to ensure individual focus and interaction during sessions.', icon: 'pi pi-users' },
        { title: 'Equal Opportunity', description: 'Workshop concept and regular tasks for equal participation and learning.', icon: 'pi pi-equals' },
        { title: 'To Be an All-Rounder', description: 'Comprehensive training to become a versatile full stack web developer.', icon: 'pi pi-briefcase' },
    ];

    const breadcrumbItems = [
        { label: 'Home', url: '/' },
        { label: 'Pages', url: '/pages' },
        { label: 'About Us', url: '/about' }
    ];

    const items = [
        'Web Development', 'Mobile App Development', 'Digital Marketing', 'Graphic Design', 'Software Solutions',
    ];

    return (
        <div className="overflow-hidden w-full">
             <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content=">Empowering businesses through innovative digital solutions"
        />
        <meta name="keywords" content="Streakify, about" />
        <link rel="canonical" href="https://streakify.in/about" />
      </Helmet>


            {/* About Us Section */}
            <div className="relative bg-cover bg-center w-full h-[300px]" style={{ backgroundImage: "url('/assets/img/about.jpg')" }}>
                <div className="absolute inset-0 bg-gradient-to-r from-sky-500 to-blue-500 opacity-20"></div>
                <div className="relative z-10 flex flex-col justify-center items-center text-center text-white py-16 w-full h-full">
                    <h1 className="text-5xl font-bold mb-4">About Us</h1>
                    <p className="text-lg mb-8">Discover why Streakify stands out among the rest.</p>
                </div>
            </div>

            {/* About Section Content */}
            <section className="py-20 px-4 bg-gray-100 dark:bg-gray-800">
                <div className="max-w-7xl mx-auto text-center">
                    <h2 className="text-3xl font-bold mb-8 text-slate-900 dark:text-white">
                        What <span className="text-sky-900 dark:text-white">Streakify Technologies</span> Does?
                    </h2>
                    <p className="text-lg mb-12 text-gray-700 dark:text-gray-300">
                        Efficiently design integrated solutions and build strategic networks for advanced results.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        {services.map((service, index) => (
                            <ServiceCard key={index} {...service} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-20 px-4 bg-white dark:bg-gray-900">
                <div className="max-w-6xl mx-auto text-center">
                    <h2 className="text-base font-semibold leading-7 text-sky-500 dark:text-sky-400">
                        Our Mission
                    </h2>
                    <h2 className='mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-4xl'>
                        Empowering businesses through innovative digital solutions.
                    </h2>
                    <p className="text-lg mb-12 text-gray-700 dark:text-gray-300 p-6">
                        Architecting innovative solutions to drive your business forward.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        {sections.map((section, index) => (
                            <SectionCard key={index} {...section} />
                        ))}
                    </div>
                </div>
            </section>

            <section className="py-20 px-4 bg-gray-100 dark:bg-gray-800">
                <div className="max-w-6xl mx-auto text-center">
                    <h2 className="text-base font-semibold leading-7 text-sky-500 dark:text-sky-400">
                        Our Courses
                    </h2>
                    <h2 className='mt-2 text-3xl font-extrabold leading-8 tracking-tight text-gray-900 dark:text-white sm:text-4xl'>
                        Discover What We Offer
                    </h2>
                    <p className="text-lg mb-12 text-gray-700 dark:text-gray-300">
                        Explore our training programs and learn why they stand out in the industry.
                    </p>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
                        {cour.map((course, index) => (
                            <CourseCard key={index} {...course} />
                        ))}
                    </div>
                </div>
            </section>
        </div>

    );
};

export default About;
